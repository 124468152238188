import { ReducersTypes as reducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import TokenManager from "utils/TokenManager";
import { REQUEST_METHODS } from "./constant";

//add to combine reducers to access it globally
export const initFilters = {
  page: 1,
  username: {},
  client_id: `${TokenManager.getClientId()}`,
  sort_by: "timestamp",
  from_date: moment().format("MM/DD/YYYY"),
  to_date: moment().format("MM/DD/YYYY"),
  corporate_entity_id: "",
  request_method: REQUEST_METHODS?.find((method) => method.id === 99),
  sort_order: "desc",
};

export const initialData = {
  auditList: [],
  isFetching: false,
  exportList: [],
  next_num: null,
  prev_num: null,
  pages: 1,
  filters: initFilters,
  sort_by: "",
  sort_order: "",
};

const reportsAuditLogs = (state = initialData, action) => {
  const { type, payload } = action;
  switch (type) {
    case reducersTypes.SET_AUDIT_LOGS_LIST_SUCCESS: {
      const { result, next_num, prev_num, pages } = payload?.data || {};

      return {
        ...state,
        auditList: result || [],
        isFetching: false,
        next_num,
        prev_num,
        pages,
      };
    }

    case reducersTypes.SET_AUDIT_LOGS_LIST_FAIL: {
      return { ...state, ...payload };
    }
    case reducersTypes.SET_AUDIT_LOGS_FILTERS: {
      return { ...state, filters: payload };
    }
    case reducersTypes.FETCHING_AUDIT_LOGS: {
      return { ...state, ...(payload || {}) };
    }
    default:
      return state;
  }
};

export default reportsAuditLogs;
