import fe_config from "constants/Configs";
import TokenManager from "utils/TokenManager";

const {
  MODE,
  CLIENT_IDS_FOR_DEMO,
  CLIENT_ID_STORCENTRIC,
  CLIENT_IDS_TO_HIDE_CHARTS,
} = fe_config;

const shoutiPharmaClientId = 27;
const flexibleClientId = 124;

/**
 * function name should be 'page_type_name'
 */
const clientId = () => TokenManager.getClientId();
export function enableDEVandDemoOnly() {
  return MODE === "DEV" || CLIENT_IDS_FOR_DEMO.includes(clientId());
}

function vendorlist_chart_paymentmix() {
  return MODE === "DEV" || CLIENT_IDS_FOR_DEMO.includes(clientId());
}
function vendorlist_chart_paymentmethod() {
  return MODE === "DEV" || CLIENT_IDS_FOR_DEMO.includes(clientId());
}
function customerlist_edit_workflow() {
  return !TokenManager.getIsUserOnly();
}
export function payablelist_column_paymentmethod() {
  return MODE === "DEV" || CLIENT_IDS_FOR_DEMO.includes(clientId());
}
function report_cashforecasting() {
  return MODE === "DEV" || CLIENT_IDS_FOR_DEMO.includes(clientId());
}
function report_button_scenariomodeling() {
  return MODE === "DEV" || CLIENT_IDS_FOR_DEMO.includes(clientId());
}
function report_button_reconcile() {
  return MODE === "DEV" || ![CLIENT_ID_STORCENTRIC].includes(clientId());
}
function setting_ar_rep_settings() {
  return TokenManager.getIsAR_REP() || TokenManager.isReadOnlyARUser();
}
function setting_company_userdetails() {
  return TokenManager.getHighestRoleId() < 3;
}
function setting_company_financialgoals() {
  return TokenManager.getIsAdmin() && !TokenManager.getIsEntityAdmin();
}
function setting_company_accounts_update() {
  return !TokenManager.getIsUserOnly();
}
function setting_company_paymentconnection() {
  return (
    TokenManager.getIsGroupAdmin() ||
    TokenManager.getIsPayAdmin() ||
    TokenManager.getIsAdminOnly()
  );
}
function setting_company_permissions() {
  return MODE === "DEV" && !TokenManager.getIsUserOnly();
}
function setting_company_general() {
  return !TokenManager.getIsUserOnly() && !TokenManager.getIsEntityAdmin();
}
function setting_company_companyheirarchy() {
  return TokenManager.getIsPayAdmin() || TokenManager.getIsGroupAdmin();
}
function setting_company_corpentities() {
  return !TokenManager.getIsUserOnly();
}
function setting_data_integrations() {
  return !TokenManager.getIsEntityAdmin();
}
function setting_data_import() {
  return !TokenManager.getIsEntityAdmin();
}
export function enableOpenSearch() {
  return MODE === "DEV"
    ? [
        7, 53, 72, 95, 102, 104, 112, 113, 114, 116, 117, 122, 139, 140,
      ].includes(clientId())
    : [
        1, 3, 7, 42, 47, 54, 55, 59, 60, 72, 74, 82, 83, 91, 93, 97, 98, 111,
        113, 118, 116, 161, 165,
      ].includes(clientId());
}
function insights_ap_chart_paymenttermsbyvalue() {
  return MODE === "DEV" || CLIENT_IDS_FOR_DEMO.includes(clientId());
}
function insights_ap_chart_paymenttermsaftermovetocard() {
  return MODE === "DEV" || CLIENT_IDS_FOR_DEMO.includes(clientId());
}

function hideChartsForClientProd() {
  return MODE === "PROD" && CLIENT_IDS_TO_HIDE_CHARTS.includes(clientId());
}

export const enableAPIForAdaptive = () => {
  return (
    (MODE === "DEV" && clientId() === 139) ||
    (MODE === "PROD" && clientId() === 93)
  );
};

export const enableForF45SBX = () => {
  return (
    MODE === "DEV" && clientId() === 186
    // (MODE === "PROD" && clientId() === 93)
  );
};

export const enableAPIForBestbath = () => {
  return (
    (MODE === "DEV" && clientId() === 139) ||
    (MODE === "PROD" && clientId() === 149)
  );
};

export const checkAutoPayCheckDefault = () => {
  return (
    (MODE === "DEV" && clientId() === 114) ||
    (MODE === "PROD" && clientId() === 166)
  );
};

export function callNewAPTotalInDev() {
  return (
    MODE === "DEV" || (MODE === "PROD" && [3, 93, 44].includes(clientId()))
  );
}

export function getInvoiceLineClient() {
  return MODE === "DEV" ? clientId() === 145 : [105, 144].includes(clientId());
}

function reconcileFilterChangeForDemo() {
  return (
    (MODE === "PROD" && clientId() === 3) ||
    (MODE === "DEV" && clientId() === 89)
  );
}

export function enableCreateInvoice() {
  return (MODE === "PROD" && clientId() === 3) || MODE === "DEV";
}

export function enableStatementFieldsGSC() {
  return (
    (MODE === "PROD" && clientId() === 60) ||
    (MODE === "DEV" && clientId() === 104)
  );
}

export function enableEditInvoiceButtonEmail() {
  return (
    (MODE === "PROD" && [60, 180].includes(clientId())) ||
    (MODE === "DEV" && clientId() === 104)
  );
}

export function enableForISSA() {
  return (
    (MODE === "DEV" && [145].includes(clientId())) ||
    (MODE === "PROD" && [105].includes(clientId()))
  );
}

export function enableForGC() {
  return MODE === "PROD" && [144].includes(clientId());
}

export function enableCustomerDepositTabForNonNetSuite() {
  return MODE === "PROD" && CLIENT_IDS_FOR_DEMO.includes(clientId());
}

export function enableCategoryLevelTotal() {
  return (
    (MODE === "DEV" && [145].includes(clientId())) ||
    (MODE === "PROD" && [105].includes(clientId()))
  );
}

export function hideCustomFieldForHardHatCustomer() {
  return (
    (MODE === "DEV" && [198].includes(clientId())) ||
    (MODE === "PROD" && [173].includes(clientId()))
  );
}

export function enableAgreegateJournal() {
  return (
    (MODE === "DEV" && [145, 114].includes(clientId())) ||
    (MODE === "PROD" && [143, 160, 169].includes(clientId()))
  );
}

export function enableForWindermere() {
  return (
    (MODE === "DEV" && [37].includes(clientId())) ||
    (MODE === "PROD" && [109].includes(clientId()))
  );
}

export function enableCreditApplication() {
  return (
    MODE === "DEV" ||
    (MODE === "PROD" && [...CLIENT_IDS_FOR_DEMO, 168].includes(clientId()))
  );
}

export function enableForTortuga() {
  return (
    (MODE === "DEV" && [89, 136].includes(clientId())) ||
    (MODE === "PROD" && clientId() === 143)
  );
}

export function enableForVoltyx() {
  return (
    (MODE === "DEV" && clientId() === 72) ||
    (MODE === "PROD" && [145, 153, 154, 155].includes(clientId()))
  );
}

export function enableDefaultAllDateFilter() {
  return (
    (MODE === "DEV" && clientId() === 114) ||
    (MODE === "PROD" && [145, 153, 154, 155, 100004].includes(clientId()))
  );
}

export function disableCustomerTabForOpenEye() {
  return (
    (MODE === "DEV" &&
      TokenManager.isCustomer() &&
      [95].includes(clientId())) ||
    (MODE === "PROD" && TokenManager.isCustomer() && [111].includes(clientId()))
  );
}

export function enableTransactionFeesColumn() {
  return (
    (MODE === "DEV" && [114].includes(clientId())) ||
    (MODE === "PROD" && [111, 124].includes(clientId()))
  );
}

export function enableForNobel() {
  return (
    (MODE === "DEV" && [102, 114, 89, 188].includes(clientId())) ||
    (MODE === "PROD" && clientId() === 116)
  );
}

export function enableCustomFieldsCSV() {
  return (
    (MODE === "DEV" && [89, 188].includes(clientId())) ||
    (MODE === "PROD" && [116, 164].includes(clientId()))
  );
}

export function enableOBTabs() {
  return MODE === "DEV" || (MODE === "PROD" && clientId() === 116);
}

export function enableForShouti() {
  return (
    (MODE === "DEV" && clientId() === 53) ||
    (MODE === "PROD" && clientId() === shoutiPharmaClientId)
  );
}

export function enableForFlexible() {
  return (
    (MODE === "DEV" && clientId() === 72) ||
    (MODE === "PROD" && clientId() === flexibleClientId)
  );
}

export function enableCategoryViewCF() {
  return (
    (MODE === "DEV" && clientId() === 207) ||
    (MODE === "PROD" && clientId() === 177)
  );
}

export function enableTimecardUpload() {
  return (
    (MODE === "DEV" && [112].includes(clientId())) ||
    (MODE === "PROD" && [182].includes(clientId()))
  );
}

export function enableFileUpload() {
  return (
    (MODE === "DEV" && [114, 139, 85, 112].includes(clientId())) ||
    (MODE === "PROD" &&
      [...CLIENT_IDS_FOR_DEMO, 145, 153, 154, 155, 182].includes(clientId()))
  );
}

export function enableStatementFileNameToEntity() {
  return (
    (MODE === "DEV" && [114].includes(clientId())) ||
    (MODE === "PROD" && [55].includes(clientId()))
  );
}

export function enableCurrencyDropdownInPayment() {
  return (
    (MODE === "DEV" && [114].includes(clientId())) ||
    (MODE === "PROD" && [55].includes(clientId()))
  );
}

export function enableEasyPayPG() {
  const accountsData = TokenManager && TokenManager.getPaymentAccountTypeData();
  return accountsData && accountsData.account_type === "EASYPAY";
}
export function enableWorldPayPG() {
  const accountsData = TokenManager && TokenManager.getPaymentAccountTypeData();
  return accountsData && accountsData.account_type === "WORLDPAY";
}

export function enableForGoVirtual() {
  return MODE === "DEV" && [203, 181, 89].includes(clientId());
}

export const enableMiscLinesGrouping = () => {
  return true;
};

export function enableWeeklyToggle() {
  return (
    (MODE === "DEV" && clientId() === 114) ||
    (MODE === "PROD" && clientId() === 116)
  );
}

export function enableForArDemo() {
  return (
    (MODE === "DEV" && clientId() === 72) ||
    (MODE === "PROD" && CLIENT_IDS_FOR_DEMO.includes(clientId()))
  );
}

export function enableForHardHat() {
  return (
    (MODE === "DEV" && clientId() === 198) ||
    (MODE === "PROD" && clientId() === 173)
  );
}

export function enableRemittanceParse() {
  return MODE === "DEV";
}

export function enableCustomerInvoiceRestrictedCreation() {
  return (
    (MODE === "DEV" && clientId() === 195) ||
    (MODE === "PROD" && clientId() === 189)
  );
}

export function enableHubspotSupportForm() {
  return TokenManager.getAllAdmins() && (MODE === "DEV" || MODE === "PROD");
}

export function offsetArApForLampplus() {
  return (
    (MODE === "PROD" && clientId() === 193) ||
    (MODE === "DEV" && clientId() === 180)
  );
}

export function disablePartialInvoicePayment() {
  return (
    (MODE === "PROD" && clientId() === 198) ||
    (MODE === "DEV" && clientId() === 102)
  );
}

export function enableForAIR() {
  return (
    (MODE === "DEV" && [114].includes(clientId())) ||
    (MODE === "PROD" && [200].includes(clientId()))
  );
}

export function enableCashApplication() {
  return ( MODE === "DEV" || (MODE === "PROD" && [3, 112, 192, 209, 210].includes(clientId()))); // both demo and KT sbx and RP
}

export function enablePayStatementBtn() {
  return MODE === "DEV" || CLIENT_IDS_FOR_DEMO.includes(clientId()); 
}

const features = {
  vendorlist_chart_paymentmix: vendorlist_chart_paymentmix(),
  vendorlist_chart_paymentmethod: vendorlist_chart_paymentmethod(),
  customerlist_edit_workflow: customerlist_edit_workflow(),
  payablelist_column_paymentmethod: payablelist_column_paymentmethod(),
  report_cashforecasting: report_cashforecasting(),
  report_button_scenariomodeling: report_button_scenariomodeling(),
  report_button_reconcile: report_button_reconcile(),
  setting_ar_rep_settings: setting_ar_rep_settings(),
  setting_company_userdetails: setting_company_userdetails(),
  setting_company_financialgoals: setting_company_financialgoals(),
  setting_company_accounts_update: setting_company_accounts_update(),
  setting_company_permissions: setting_company_permissions(),
  setting_company_paymentconnection: setting_company_paymentconnection(),
  setting_company_general: setting_company_general(),
  setting_company_companyheirarchy: setting_company_companyheirarchy(),
  setting_company_corpentities: setting_company_corpentities(),
  setting_data_integrations: setting_data_integrations(),
  setting_data_import: setting_data_import(),
  insights_ap_chart_paymenttermsbyvalue:
    insights_ap_chart_paymenttermsbyvalue(),
  insights_ap_chart_paymenttermsaftermovetocard:
    insights_ap_chart_paymenttermsaftermovetocard(),
  hideChartsForClientProd: hideChartsForClientProd(),
  reconcileFilterChangeForDemo: reconcileFilterChangeForDemo(),
  enableStatementFieldsGSC: enableStatementFieldsGSC(),
  enableForISSA: enableForISSA(),
  enableWeeklyToggle: enableWeeklyToggle(),
};

export default features;
