export const REQUEST_METHODS = [
  { id: 99, label: "ALL", value: "" },
  // { id: 1, label: "GET", value: "get" },
  { id: 2, label: "POST", value: "post" },
  { id: 3, label: "PUT", value: "put" },
  { id: 4, label: "DELETE", value: "delete" },
  { id: 5, label: "PATCH", value: "patch" },
  { id: 6, label: "OPTIONS", value: "options" },
  { id: 7, label: "HEAD", value: "head" },
];

export const ANALYTICS_AUDIT_LOGS_FILTERS = "ANALYTICS_AUDIT_LOGS_FILTERS";
