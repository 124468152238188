import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import {getUXSettingsFromStorage} from "utils";
import { getDateByRangeInterval } from "utils/dates";
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import { getLocaleString } from "utils/localization/locale";
import { enableDefaultAllDateFilter } from "handlers/features";
const dateRange = {
  startDate: enableDefaultAllDateFilter() ? moment(0).format("MM/DD/YYYY") : moment().subtract(2, "year").format("MM/DD/YYYY"), 
  endDate: enableDefaultAllDateFilter() ? moment().add(1, "year").format("MM/DD/YYYY") : moment().add(365, "days").format("MM/DD/YYYY")
};
export const columnWidthVendor = {
  name: 200,
  category: 100,
  amount_due: 100,
  vendor_notes: 40,
  payment_terms: 125,
  amount_not_due: 100,
  aggregate_credit: 120,
  unapplied_payment: 130,
  total_amount_due: 120,
  average_days_late: 120,
  weighted_avg_days_late: 120,
};

export const supplierListColumns = {
  [getLocaleString("common.vendor", "Vendor")]: true,
  [getLocaleString("common.category", "Category")]: true,
  [getLocaleString("dashboard.current", "Current")]: true,
  [getLocaleString("customer.overdue", "Overdue")]: true,
  [getLocaleString("dashboard.total_balance", "Total Balance")]: true,
  [getLocaleString("reconcile.credit", "Credit")]: true,
  [getLocaleString("common.unapplied_payments", "Unapplied Payments")]: true,
  [getLocaleString("dashboard.avg_days_late", "Avg Days Late")]: true,
  [getLocaleString("dashboard.wt_avg_days_late", "Weighted(avg) Days Late")]: true,
  [getLocaleString("dashboard.payment_terms", "Payment Terms")]: true,
  [getLocaleString("common.internal_id", "Internal ID")]: false,
  [getLocaleString("common.notes", "Notes")]: true,
};
let i = 1;
export const supplierListColumnOrder = mapValues(supplierListColumns, () => i++);
i = 1;

export const startDate = dateRange.startDate;
export const endDate = dateRange.endDate;
let savedFilters = getUXSettingsFromStorage("SUPPLIER_LIST_FILTERS");
if (savedFilters && savedFilters.interval) {
  const { interval, from_date, to_date } = savedFilters;
  const [sd, ed] = getDateByRangeInterval(interval === 'All' ? 'AllToDate' : interval, from_date, to_date);
  savedFilters = {
    ...savedFilters,
    from_date: sd,
    to_date: ed,
    originalstartDate: sd,
    originalendDate: ed,
  };
}
export const initVendorListData = {
  visibleColumns: {
    ...supplierListColumns,
    ...getUXSettingsFromStorage("SUPPLIER_LIST_COLUMNS"),
  },
  resizedState: [],
  customFilters: [],
  vendorList: [],
  minColWidth: columnWidthVendor,
  searchedVendorList: [],
  isSearchVendorFetching: false,
  isVendorListFetching: true,
  isExporting: false,
  exportedData: [],
  hideColumns: [],
  vendorListFilters: {
    status: "open",
    sort_by: "total_amount_due",
    to_date: endDate,
    originalstartDate: startDate,
    originalendDate: endDate,
    per_page: 20,
    category: "",
    from_date: startDate,
    with_notes: 1,
    sort_order: "desc",
    vendor_category_id: "",
    selectedSection: null,
    reloadChart: false,
    ...savedFilters,
    page: 1
  },
  getCustomFields: true,
};

export const BILL_CREDIT_FLAGS = {
  showBillCredit: false,
  showUnappliedAmount: false
};

const vendorListing = (state = initVendorListData, action) => {
  switch (action.type) {
    case ReducersTypes.VENDOR_LIST: {
      return {
        ...state,
        ...action.payload,
        isVendorListFetching: action?.payload?.isVendorListFetching,
        vendorList: action?.payload?.vendorList || state.vendorList,
      };
    }
    case ReducersTypes.VENDOR_LIST_UPDATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ReducersTypes.RESET_VENDOR_LIST:
      return { ...initVendorListData };
    case ReducersTypes.SET_VENDOR_FILTER:
      return {
        ...state,
        vendorListFilters: {
          ...state.vendorListFilters,
          ...action.payload,
        },
      };
    case ReducersTypes.RESET_VENDOR_FILTER:
      return {
        ...state,
        vendorListFilters: {
          page: 1,
          status: "open",
          sort_by: "total_amount_due",
          to_date: endDate,
          originalstartDate: startDate,
          originalendDate: endDate,
          per_page: 20,
          category: "",
          from_date: startDate,
          with_notes: 1,
          sort_order: "desc",
          vendor_category_id: "",
          selectedSection: null,
          activeKey: action?.payload?.activeKey,
          reloadChart: get(action,"payload.reloadChart",true)
        },
      };
    case ReducersTypes.HEADER_REFRESH_TOGGLE:
      return {
        ...state,
        vendorList: [],
        vendorListFilters: {
          page: 1,
          status: "open",
          sort_by: "total_amount_due",
          to_date: endDate,
          originalstartDate: startDate,
          originalendDate: endDate,
          per_page: 20,
          category: "",
          from_date: startDate,
          with_notes: 1,
          sort_order: "desc",
          vendor_category_id: "",
          selectedSection: null,
          activeKey: action?.payload?.activeKey,
        },
      };
    default:
      return state;
  }
};

export default vendorListing;
