/* eslint-disable react-hooks/exhaustive-deps */
import { ux_istrue } from "handlers/ux";
import get from "lodash/get";
import React, { useState, useEffect } from "react";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import TokenManager from "utils/TokenManager";
import { getLocaleString } from "utils/localization/locale";
import Constants from "constants/index";
import EllipsisOverlay from "./EllipsisOverlay";

const NavIcon = ({ element, loadingNav, openleftnav }) =>
  !openleftnav ? (
    <OverlayTrigger
      placement="top-end"
      overlay={
        <Tooltip style={{ marginBottom: "5px" }}>{element.name}</Tooltip>
      }
    >
      <i
        style={{ width: "15px" }}
        className={
          element.ilink === loadingNav
            ? "fas fa-spinner fa-spin me-3"
            : element.ilink
        }
      />
    </OverlayTrigger>
  ) : (
    <i
      style={{ width: "15px" }}
      className={
        element.ilink === loadingNav
          ? "fas fa-spinner fa-spin me-3"
          : element.ilink
      }
    />
  );

const LeftNav = (props) => {
  const { ux, history } = props;
  const {
    clientFeatures: {
      CLIENT_JOURNAL_SECTION_ENABLED,
      CLIENT_PAYROLL_SECTION_ENABLED,
      CLIENT_CASHFLOW_SECTION_ENABLED,
      CLIENT_PAYABLES_SECTION_ENABLED,
      CLIENT_RECEIVABLES_SECTION_ENABLED,
      CLIENT_CASHAPP_SECTION_ENABLED,
      CLIENT_CASHAPP_SERRALA_ENABLED,
    },
  } = props;

  const isARRepRole =
    TokenManager.getIsAR_REP() || TokenManager.isReadOnlyARUser();
  const isAPRepRole = TokenManager.getIsAP_REP();
  const driverAdminRole = TokenManager.getDriverAdmin();
  const readOnlyAdmin =
    TokenManager.isReadOnlyAdmin() && !TokenManager.isReadOnlyARUser();

  const NAV = [
    {
      name: getLocaleString("page_names.dashboard", "Dashboard"),
      ilink: "fas fa-tachometer-alt me-3",
      route: "/dashboard",
      localStorageKey: "dashboardrange",
      subStorages: [],
      show: !isAPRepRole && !driverAdminRole,
    },
    {
      name: getLocaleString("page_names.cashapp", "Cash Application"),
      ilink: "fas fa-money-check-alt me-3",
      route: Constants.CashAppRoute,
      localStorageKey: "",
      subStorages: [],
      show:
        CLIENT_CASHAPP_SECTION_ENABLED &&
        CLIENT_CASHAPP_SERRALA_ENABLED &&
        TokenManager.getAllAdmins(),
    },
    {
      name: getLocaleString("page_names.cashflow", "Cash Flow"),
      ilink: "fntw fas fa-list me-3",
      route: "/reports",
      localStorageKey: "",
      subStorages: ["refresh_reports", "showScenarioPage", "selectedSecnario"],
      show:
        CLIENT_CASHFLOW_SECTION_ENABLED &&
        !isARRepRole &&
        !isAPRepRole &&
        !driverAdminRole &&
        !readOnlyAdmin,
    },
    {
      name: getLocaleString("page_names.payroll", "Payroll"),
      ilink: "fntw fas fa-money me-3",
      route: "/payroll",
      localStorageKey: "payrollFilters",
      subStorages: ["payrollBulkUpdateIds"],
      show:
        CLIENT_PAYROLL_SECTION_ENABLED &&
        !isARRepRole &&
        !isAPRepRole &&
        !driverAdminRole &&
        !readOnlyAdmin,
    },
    {
      name: getLocaleString("page_names.payables", "Payables"),
      ilink: "fntw fas fa-file-invoice-dollar me-3",
      route: "/payables",
      localStorageKey: "payableFilters",
      uxKey: "PAYABLE_FILTERS",
      subStorages: [],
      subNav: [
        {
          name: getLocaleString("page_names.purchase_order", "Purchase Orders"),
          ilink: "fntw fas fa-file-invoice-dollar me-3",
          route: "/purchase-orders",
          uxKey: "PO_FILTERS",
          localStorageKey: "purchaseBacklogFilters",
          subStorages: [],
          show: CLIENT_PAYABLES_SECTION_ENABLED && !readOnlyAdmin,
        },
        {
          name: getLocaleString("page_names.non_inv_exp", "Non Invoiced Exp"),
          ilink: "fntw fas fa-file-invoice-dollar me-3",
          route: "/non-invoiced-expenses",
          uxKey: "NON_AP_FILTERS",
          localStorageKey: "payableNonInvoicedExpensesFilters",
          subStorages: [],
          show: CLIENT_PAYABLES_SECTION_ENABLED && !readOnlyAdmin,
        },
      ],
      show: CLIENT_PAYABLES_SECTION_ENABLED && !isARRepRole && !driverAdminRole,
    },
    {
      name: getLocaleString("page_names.receivables", "Receivables"),
      ilink: "fas fa-hand-holding-usd me-3",
      route: "/receivables",
      uxKey: "RECEIVABLE_FILTERS",
      localStorageKey: "receivableFilters",
      subStorages: [],
      subNav: [
        {
          name: getLocaleString("page_names.order_backlog", "Order Backlog"),
          ilink: "fas fa-hand-holding-usd me-3",
          route: "/backlog",
          uxKey: "OB_FILTERS",
          localStorageKey: "backlogFilters",
          subStorages: [],
          show: CLIENT_RECEIVABLES_SECTION_ENABLED,
        },
      ],
      show:
        CLIENT_RECEIVABLES_SECTION_ENABLED && !isAPRepRole && !driverAdminRole,
    },
    {
      name: getLocaleString("page_names.journal", "Journal"),
      ilink: "fas fa-book me-3",
      route: "/journal",
      localStorageKey: "journalInflowFilters",
      subStorages: [],
      show:
        CLIENT_JOURNAL_SECTION_ENABLED &&
        !isARRepRole &&
        !isAPRepRole &&
        !driverAdminRole &&
        !readOnlyAdmin,
    },
    {
      name: getLocaleString("page_names.vendors", "Vendors"),
      ilink: "fas fa-user-tag me-3",
      route: "/vendors",
      localStorageKey: "vendorsFilters",
      subStorages: ["vendor_activeTab", "activeTabSelection"],
      show: CLIENT_PAYABLES_SECTION_ENABLED && !isARRepRole,
    },
    {
      name: getLocaleString("page_names.customers", "Customers"),
      ilink: "fas fa-users me-3",
      route: "/customers",
      localStorageKey: "customersFilters",
      uxKey: "CUSTOMER_LISTING_PAGE",
      subStorages: [
        "customer_activeTab",
        "customer_details_activeTab",
        "activeTabSelection",
        "customerListColumns",
      ],
      show:
        CLIENT_RECEIVABLES_SECTION_ENABLED && !isAPRepRole && !driverAdminRole,
    },
    {
      name: getLocaleString("page_names.saved_searches", "Saved Searches"),
      ilink: "fas fa-search me-3",
      route: "/saved-search",
      localStorageKey: "",
      subStorages: [],
      show: TokenManager.getAllAdmins(),
    },
    {
      name: getLocaleString("page_names.reports", "Reports"),
      ilink: "fntw fas fa-chart-bar me-3",
      route: "/analytics",
      localStorageKey: "paymentsPageFilters",
      subStorages: ["paymentsPageFilters", "insightsEmailFilters"],
      show: !isAPRepRole,
    },
    {
      name: getLocaleString("page_names.insights", "Insights"),
      ilink: "fntw fas fa-chart-line me-3",
      route: "/insights",
      localStorageKey: "",
      subStorages: ["inventoryFilters", "insightsEmailFilters"],
      show: !isARRepRole && !isAPRepRole && !driverAdminRole,
    },
    {
      name: getLocaleString("page_names.settings", "Settings"),
      ilink: "fas fa-cog me-3",
      route: "/settings",
      localStorageKey: "",
      subStorages: [],
      show: true,
    },
    {
      name: getLocaleString("page_names.help", "Help"),
      ilink: "fas fa-question-circle me-3",
      route: "/help",
      localStorageKey: "",
      subStorages: [],
      show: !driverAdminRole,
    },
    {
      name: getLocaleString("page_names.support", "Support"),
      ilink: "fas fa-user-cog me-3",
      route: "/support",
      localStorageKey: "",
      subStorages: [],
      show: TokenManager.getIsPayAdmin(),
    },
  ];

  const openleftnav = ux_istrue(ux.NAVIGATION_PANEL_EXPANDED);
  const [loadingNav, setloadingNav] = useState("");

  useEffect(() => {
    if (history.location.pathname === "/" && TokenManager.getIsAR_REP()) {
      window.location = "/customers";
    }
    localStorage.removeItem("refresh_reports");
    sessionStorage.removeItem("reload_entity_id");
    sessionStorage.removeItem("refresh_reports_type");
  }, []);

  // function clearOtherLocalStorages(currentlocalStorageKey) {
  //   NAV.forEach((element) => {
  //     if (element.localStorageKey !== currentlocalStorageKey) {
  //       element.subStorages.forEach((storeKey) => {
  //         localStorage.removeItem(storeKey);
  //       });
  //       element.subNav &&
  //         element.subNav.forEach((nav) => {
  //           localStorage.removeItem(nav.localStorageKey);
  //           nav.subStorages.forEach((storeKey) => {
  //             localStorage.removeItem(storeKey);
  //           });
  //         });
  //       localStorage.removeItem(element.localStorageKey);
  //       element.uxKey && setuxvalue(element.uxKey, "");
  //     }
  //   });
  // }

  const renderNavLink = (element) => (
    <NavLink
      key={element.name}
      to={element.route}
      data-cy={element.name}
      activeClassName="activet"
      onClick={(e) => {
        localStorage.setItem("event_type", "navigation");
        localStorage.setItem(
          "event_resource",
          `Page-${element.name.split(/\s/).join("")}-Menu`
        );

        if (element.customAction) {
          element.customAction(e, setloadingNav);
          return;
        }
        if (element.name === "Cash Flow") {
          localStorage.setItem("cashflow-event", "navigate");
          history.push(element.route);
          window.location.reload();
        }
      }}
      className={`list-group-item list-group-item-action flex-column align-items-start ${
        get(history, "location.pathname", "") === "/" &&
        element.name === "Dashboard"
          ? "activet"
          : ""
      } ${element.isdisabled ? "cursour-not-allowed text-secondary" : ""}`}
    >
      <div className="d-flex w-100 justify-content-start align-items-center">
        <NavIcon
          element={element}
          loadingNav={loadingNav}
          openleftnav={openleftnav}
        />

        <span className={openleftnav ? "menu-collapsed" : "menu-collapsed w-0"}>
          <EllipsisOverlay title={element.name} width={180} />
        </span>
      </div>
    </NavLink>
  );

  return (
    <div
      id="sidebar-container"
      className={openleftnav ? "sidebar-expanded" : "sidebar-collapsed"}
    >
      <div className="list-group sidebarfixed non-sticky-header-container hide-scroll">
        {NAV.filter((d) => d.show).map((element, i) => {
          if (element.subNav && element.subNav.length) {
            return (
              <div key={i}>
                <Accordion
                  className="bg-light-grey no-arrow-accordion"
                  defaultActiveKey={
                    element.subNav &&
                    element.subNav.filter((el) =>
                      get(history, "location.pathname", "").includes(el.route)
                    ).length !== 0
                      ? i
                      : null
                  }
                >
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header as="div">
                      {renderNavLink(element)}
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                      <div>
                        {element.subNav ? (
                          <div
                            className={`subnav ${openleftnav ? "ms-4" : ""}`}
                          >
                            {element.subNav
                              .filter((d) => d.show)
                              .map((el) => renderNavLink(el))}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            );
          }
          return renderNavLink(element);
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ux: state.ux,
  clientFeatures: state.configurableSettings.clientFeatures,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeftNav)
);
