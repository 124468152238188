import { useState, useLayoutEffect } from "react";

const useEllipsisEnabled = (ref) => {
  const [isEllipsisApplied, setIsEllipsisApplied] = useState(false);

  useLayoutEffect(() => {
    const checkEllipsis = () => {
      if (ref.current) {
        setIsEllipsisApplied(ref.current.scrollWidth > ref.current.offsetWidth);
      }
    };

    checkEllipsis();

    window.addEventListener("resize", checkEllipsis);

    return () => {
      window.removeEventListener("resize", checkEllipsis);
    };
  }, [ref]);

  return isEllipsisApplied;
};

export default useEllipsisEnabled;
