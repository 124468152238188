/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { getLocaleString } from "utils/localization/locale";
import {
  setconfirmationboxData,
  resetconfirmationboxData,
} from "components/ConfirmationBox";
import ListingTable from "components/ReactTable";
import get from "lodash/get";
import DateRangePicker from "components/DateRangePicker";
import Button from "components/Button";
import Dropdown from "components/Button/Dropdown";
import moment from "moment";
import FileProcess from "./file_process";
import Toaster from "components/Toaster";
import Modal from "components/CustomModal";
import { utcToLocal } from "utils/dates";
import omit from "lodash/omit";
import { setuxvalue, getuxvalue } from "handlers/ux";
import {
  getFileList,
  getFileFormats,
  deleteRemittance,
  uploadRemittance,
  getPaymentDetails,
  downloadReportFile,
} from "./actions";

export const initialFilters = {
  page: 1,
  to_date: moment().format("MM/DD/YYYY"),
  sort_by: "",
  interval: "",
  status: "",
  from_date: moment().subtract(1, 'year').format("MM/DD/YYYY"),
  original_name: "",
  sort_order: "",
  customer_detected: "",
};

export default function CashApplication() {
  const [filters, setFilters] = useState(
    getuxvalue("CASH_APPLICATION_FILES") || initialFilters
  );
  const [fileList, setFileList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [fileFormats, setFileFormats] = useState({});
  const [showFilesModal, setShowFilesModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFetchingFiles, setIsFetchingFiles] = useState(false);
  const [showProcessWindow, setShowProcessWindow] = useState(false);
  const [isFetchingInvoices, setIsFetchingInvoices] = useState(false);
  const [paginationParams, setPaginationParams] = useState({
    next_num: 1,
    prev_num: null,
  });

  const handleClose = () => setShowFilesModal(false);

  const {
    page = 1,
    to_date = moment(),
    sort_by = "",
    interval,
    status = "",
    from_date = moment(),
    original_name = "",
    sort_order = "",
    customer_detected = "",
  } = filters;

  const { next_num, prev_num } = paginationParams;

  const updateFilters = (newFilters = {}) => {
    const finalFilters = {
      ...filters,
      ...newFilters,
    };
    setFilters(finalFilters);
    setuxvalue("CASH_APPLICATION_FILES", finalFilters);
  };

  const setDates = (from_date, to_date, interval) =>
    updateFilters({
      from_date: from_date ? moment(from_date).format("MM/DD/YYYY") : "",
      to_date: to_date ? moment(to_date).format("MM/DD/YYYY") : "",
      interval,
    });

  const getUploadedFiles = useCallback((filters) => {
    setIsFetchingFiles(true);
    getFileList({
      mutate: filters.isMutate ? 1 : "",
      ...omit(filters, ["interval", "next_num", "prev_num", "isMutate"]),
    }).then((res) => {
      setFileList(get(res, "data.result", []));
      setPaginationParams({
        next_num: get(res, "data.next_num", 1),
        prev_num: get(res, "data.prev_num", null),
      });
      setIsFetchingFiles(false);
    });
    getFileFormats().then((res) => {
      setFileFormats(get(res, "data.result", {}));
    });
  }, []);

  const getInvoices = useCallback((id, params = {}) => {
    setIsFetchingInvoices(true);
    getPaymentDetails(id, { mutate: params.isMutate ? 1 : "" }).then((res) => {
      setInvoiceList(get(res, "data.result", []));
      setIsFetchingInvoices(false);
    });
  }, []);

  useEffect(() => {
    document.title = `${getLocaleString(
      "data_import.cash_remittance",
      "Cash Remittance"
    )} - ${getLocaleString("header.appName", "Serrala")}`;
  }, []);

  useEffect(() => {
    getUploadedFiles(filters);
  }, [page, sort_by, sort_order]);

  const Increment = () => updateFilters({ page: page + 1 });

  const Decrement = () => updateFilters({ page: page - 1 });

  const handleClick = (file) => {
    setSelectedFile(file);
    getInvoices(file.id);
    setShowProcessWindow(true);
  };

  // const setSortBy = (new_sort_by) =>
  //   updateFilters({
  //     sort_by: new_sort_by,
  //     sort_order: new_sort_by !== sort_by ? "desc" : sort_order === "desc" ? "asc" : "desc",
  //     page: 1,
  //   });

  const columns = [
    {
      header: () => getLocaleString("common.sr_no", "Sr. No."),
      show: true,
      accessorKey: "id",
      id: "id",
      className: "ps-3",
      size: 50,
      dragable: false,
      cell: (data) => <div>{get(data, "row.index", 0) + 1}</div>,
    },
    {
      header: () => (
        <div
          // onClick={() => setSortBy("original_name")}
          className="cursor-pointer"
        >
          {getLocaleString("cash_remittance.file_name", "File Name")}
          <span className="sort-icons">
            <i data-tlabel="sort list" className="fas fa-caret-up"></i>
            <i data-tlabel="sort list" className="fas fa-caret-down"></i>
          </span>
        </div>
      ),
      show: true,
      accessorKey: "original_name",
      id: "name",
      size: 200,
      dragable: false,
      cell: (data) => <div>{data.getValue()}</div>,
    },
    {
      header: () => (
        <div
          // onClick={() => setSortBy("uploaded_at")}
          className="cursor-pointer"
        >
          {getLocaleString("cash_remittance.uploaded_at", "Uploaded At")}
          <span className="sort-icons">
            <i data-tlabel="sort list" className="fas fa-caret-up"></i>
            <i data-tlabel="sort list" className="fas fa-caret-down"></i>
          </span>
        </div>
      ),
      show: true,
      id: "uploaded_at",
      accessorKey: "uploaded_at",
      dragable: false,
      size: 100,
      cell: (data) => (
        <div>{data.getValue() ? utcToLocal(data.getValue()) : "-"}</div>
      ),
    },
    {
      header: () => (
        <div
          // onClick={() => setSortBy("processed_at")}
          className="cursor-pointer"
        >
          {getLocaleString("cash_remittance.processed_at", "Processed At")}
          <span className="sort-icons">
            <i data-tlabel="sort list" className="fas fa-caret-up"></i>
            <i data-tlabel="sort list" className="fas fa-caret-down"></i>
          </span>
        </div>
      ),
      show: true,
      accessorKey: "processed_at",
      id: "processed_at",
      dragable: false,
      size: 100,
      cell: (data) => (
        <div>{data.getValue() ? utcToLocal(data.getValue()) : "-"}</div>
      ),
    },
    {
      header: () => (
        <div
          // onClick={() => setSortBy("last_synced_at")}
          className="cursor-pointer"
        >
          {getLocaleString("cash_remittance.sync_at", "Synced At")}
          <span className="sort-icons">
            <i data-tlabel="sort list" className="fas fa-caret-up"></i>
            <i data-tlabel="sort list" className="fas fa-caret-down"></i>
          </span>
        </div>
      ),
      show: true,
      id: "last_synced_at",
      accessorKey: "last_synced_at",
      dragable: false,
      size: 100,
      cell: (data) => (
        <div>{data.getValue() ? utcToLocal(data.getValue()) : "-"}</div>
      ),
    },
    {
      header: () => (
        <div>{getLocaleString("common.sync_status", "Sync Status")}</div>
      ),
      show: true,
      id: "last_sync_status",
      accessorKey: "last_sync_status",
      dragable: false,
      size: 50,
      cell: (data) => <div>{data.getValue()}</div>,
    },
    {
      header: () => (
        <div>
          {getLocaleString(
            "cash_remittance.customer_detected",
            "Customer Detected"
          )}
        </div>
      ),
      show: true,
      accessorKey: "customer_detected",
      id: "customer_detected",
      dragable: false,
      size: 100,
      cell: (data) => <div>{data.getValue()}</div>,
    },
    {
      header: () => <div>{getLocaleString("common.status", "Status")}</div>,
      show: true,
      id: "status",
      accessorKey: "status",
      dragable: false,
      size: 200,
      cell: (data) => (
        <div>
          {data.getValue()}{" "}
          {get(data, "row.original.error_reason") &&
            `(${get(data, "row.original.error_reason")})`}
        </div>
      ),
    },
    {
      header: () => (
        <div>
          {getLocaleString(
            "cash_remittance.link_to_staging",
            "Link to Staging"
          )}
        </div>
      ),
      show: false,
      id: "link_to_staging",
      accessorKey: "status",
      dragable: false,
      size: 150,
      cell: (data) => (
        <div>
          {data.getValue() && data.getValue() === "PROCESSED" ? (
            <Button size="sm" onClick={() => setShowProcessWindow(true)}>
              {data.getValue()}
            </Button>
          ) : null}
        </div>
      ),
    },
    {
      header: () => <div />,
      show: true,
      id: "action",
      accessorKey: "last_sync_status",
      dragable: false,
      size: 80,
      disableClick: true,
      cell: (data) => (
        <div>
          {!data.getValue() ? (
            <Button
              onClick={(e) =>
                setconfirmationboxData({
                  variant: "warning",
                  msg: getLocaleString(
                    "receivables.are_you_sure_you_want_to_delete",
                    "Are you sure you want to delete?"
                  ),
                  onSave: () => {
                    deleteRemittance(get(data, "row.original.id", "")).then(
                      (res) => {
                        if (res) {
                          Toaster(
                            getLocaleString("common.deleted_successfully"),
                            "success"
                          );
                          getUploadedFiles({
                            ...initialFilters,
                          });
                        }
                      }
                    );
                    resetconfirmationboxData();
                  },
                })
              }
              title={getLocaleString(
                "customer_details.delete_file",
                "Delete File"
              )}
              variant="icon"
              data-tlabel={`Remittance file delete ${get(
                data,
                "row.original.id",
                ""
              )}`}
              startIcon={<i className="fa fa-trash-o t-15 color-blue" />}
            />
          ) : null}
          {get(data, "row.original.last_sync_status") === "SUCCESS" ? (
            <i className="fas fa-download text-secondary cursor-pointer" onClick={() => downloadReportFile(get(data, "row.original.id"), get(data, "row.original.original_name"))}/>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div className="row px-15" id="dashboardBody">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 text-center mb-4">
                <h5>
                  {getLocaleString(
                    "data_import.cash_remittance",
                    "Cash Remittance"
                  )}
                </h5>
              </div>
              {!showProcessWindow && (
                <div className="col-md-12">
                  <div className="dropdown d-inline-block mb-3 me-2">
                    <Dropdown
                      showDropdown={showFilter}
                      buttonProps={{
                        as: "span",
                        role: "button",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false",
                      }}
                      btnClassName="dropdown-toggle cursor-pointer"
                      setShowDropdown={() => setShowFilter(!showFilter)}
                      buttonText={getLocaleString("common.filter", "Filter")}
                      dropdownWrapperClassName="filterdroplist p-2 pe-4 width-500px"
                      dropdownWrapperProps={{
                        id: "outerside",
                      }}
                      filterButton
                    >
                      <form id="filter-form" className="dropdown-wrap">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label>
                              {getLocaleString("common.upload_date", "Upload Date")}
                            </label>
                            <div className="single-date-picker t-12 overflow-hidden">
                              <div className="t-12">
                                <DateRangePicker
                                  startDate={from_date ? moment(from_date) : ""}
                                  label={interval}
                                  endDate={to_date ? moment(to_date) : ""}
                                  isClearable={from_date || to_date}
                                  getSelectedDates={setDates}
                                  parentEl="#outerside"
                                  allToDate={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label>
                              {getLocaleString(
                                "cash_remittance.file_name",
                                "File Name"
                              )}
                            </label>
                            <input
                              type="text"
                              defaultValue={original_name}
                              onChange={(e) =>
                                updateFilters({
                                  original_name: e.target.value,
                                })
                              }
                              className="form-control t-14"
                              placeholder={getLocaleString(
                                "cash_remittance.file_name",
                                "File Name"
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>
                              {getLocaleString(
                                "cash_remittance.customer_detected",
                                "Customer Detected"
                              )}
                            </label>
                            <input
                              type="text"
                              defaultValue={customer_detected}
                              onChange={(e) =>
                                updateFilters({
                                  customer_detected: e.target.value,
                                })
                              }
                              className="form-control t-14"
                              placeholder={getLocaleString(
                                "cash_remittance.customer_detected",
                                "Customer Detected"
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>
                              {getLocaleString("common.status", "Status")}{" "}
                            </label>
                            <select
                              className="form-control w-100"
                              value={status}
                              onChange={(e) =>
                                updateFilters({ status: e.target.value })
                              }
                            >
                              <option value="">
                                {getLocaleString("common.all", "All")}
                              </option>
                              <option value="PROCESSED">
                                {getLocaleString(
                                  "cash_remittance.processed",
                                  "Processed"
                                )}
                              </option>
                              <option value="PROCESSING">
                                {getLocaleString(
                                  "cash_remittance.processing",
                                  "Processing"
                                )}
                              </option>
                              <option value="PENDING">
                                {getLocaleString(
                                  "purchase_order.pending",
                                  "Pending"
                                )}
                              </option>
                              <option value="ERROR">
                                {getLocaleString(
                                  "cash_remittance.error",
                                  "Error"
                                )}
                              </option>
                            </select>
                          </div>
                          <div className="col-md-6" />
                          <div className="col-md-6 mt-2">
                            <div className="setbtn box-space">
                              <Button
                                type="button"
                                variant="light"
                                className="resetbtn"
                                data-cy="resetbtn"
                                onClick={() => {
                                  setShowFilter(false);
                                  updateFilters(initialFilters);
                                  getUploadedFiles({
                                    ...initialFilters,
                                  });
                                }}
                              >
                                {getLocaleString("common.reset", "Reset")}
                              </Button>
                              <Button
                                type="button"
                                className="applybtn"
                                data-cy="applybtn"
                                onClick={() => {
                                  setShowFilter(false);
                                  getUploadedFiles({
                                    ...filters,
                                  });
                                }}
                              >
                                {getLocaleString("common.submit", "Submit")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Dropdown>
                  </div>

                  <Button
                    variant="success"
                    className={`cursor-pointer mb-0 me-2 ${
                      isUploading ? "disabled" : ""
                    }`}
                    as="label"
                    data-tlabel="Upload remittance"
                  >
                    <input
                      id="upload_new_file"
                      type="file"
                      className="d-none"
                      onChange={(e) => {
                        setIsUploading(true);
                        uploadRemittance(e)
                          .then((res) => {
                            if (res) {
                              Toaster(
                                getLocaleString(
                                  "common.file_upload_success",
                                  "File uploaded successfully"
                                ),
                                "success"
                              );
                              getUploadedFiles({ ...filters});
                              setIsUploading(false);
                            }
                          })
                          .catch((error) => {
                            setIsUploading(false);
                          });
                      }}
                      accept=".pdf, .csv"
                    />
                    {getLocaleString(
                      "cash_remittance.upload_remittance",
                      "Upload Remittance"
                    )}
                    {isUploading ? (
                      <i className="ms-1 fa fa-spinner fa-spin" />
                    ) : null}
                  </Button>
                  <Button
                    variant="secondary"
                    className="cursor-pointer mb-0"
                    as="label"
                    data-tlabel="Valid File formats"
                    onClick={() => setShowFilesModal(true)}
                  >
                    {getLocaleString(
                      "cash_remittance.valid_file_formats_button",
                      "Valid File formats"
                    )}
                  </Button>

                  <ListingTable
                    minRows={fileList.length > 20 ? 1 : fileList.length}
                    isFetching={isFetchingFiles}
                    columns={columns}
                    data={fileList}
                    currPage={page}
                    onRowClick={(rowInfo) => handleClick(rowInfo)}
                    sorting={
                      sort_by
                        ? [
                            {
                              id: sort_by,
                              desc: sort_order === "desc",
                            },
                          ]
                        : []
                    }
                    nextPage={next_num}
                    prevPage={prev_num}
                    setNextPage={Increment}
                    setPrevPage={Decrement}
                    noDataText={
                      isFetchingFiles
                        ? ""
                        : getLocaleString("common.nodataFound", "No data found")
                    }
                  />
                </div>
              )}
              <div className="col-md-12">
                {showProcessWindow && (
                  <FileProcess
                    selectedFile={selectedFile}
                    getInvoices={getInvoices}
                    isFetching={isFetchingInvoices}
                    invoiceList={invoiceList}
                    setShowProcessWindow={setShowProcessWindow}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showFilesModal}
        size="lg"
        onHide={handleClose}
        title={getLocaleString(
          "cash_remittance.file_formats",
          "Valid Remittance file format headers"
        )}
      >
        <div className="m-3" style={{ maxHeight: "50vh", overflowY: "auto" }}>
          {Object.keys(fileFormats).map((d) => (
            <div className="t-13">
              <strong>{d}</strong>
              {fileFormats[d].map((formats) => (
                <div className="m-2">
                  {formats.map((header, index) => (
                    <span className="pr-2">{(index ? ", " : "") + header}</span>
                  ))}
                  <br />
                </div>
              ))}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
}
