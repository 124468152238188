import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEng from "./locale_en.json";
import translationFr from "./locale_fr.json";
import translationDe from "./locale_de.json";
import translationEs from "./locale_es.json";

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translations: translationEng,
    },
    fr: {
      translations: translationFr,
    },
    de: {
      translations: translationDe,
    },
    es: {
      translations: translationEs,
    },
  },
  fallbackLng: "en",
  detection: {
    order: [
      "querystring",
      "localStorage",
      "cookie",
      "sessionStorage",
      "navigator",
      "htmlTag",
      "path",
      "subdomain",
    ],
    lookupCookie: "locale",
    lookupQuerystring: "locale",
    lookupLocalStorage: "locale",
    lookupSessionStorage: "locale",
    caches: ["localStorage", "cookie"],
  },
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations", // key to use translations

  keySeparator: ".", // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export const changeLanguage = (language = "en") => {
  i18n.changeLanguage(language);
  window.location.reload();
};

export const availableLanguages = ["en", "fr", "de", "es"];

export default i18n;
