import CURRENCY from "./CURRENCY.json";
import MESSAGES from "./MESSAGES.json";
import CONFIGS from "./Configs";
import CSVHeader from "./CSVHeaders.json";
import DATE_CONST from "./date.json";
import { getLocaleString } from "utils/localization/locale";

const ROLE_OPTIONS = [
  {
    value: "payadmin",
    label: getLocaleString("common.admin", "Admin"),
    id: 0,
    api_role_id: 1,
    isdisabled: true
  },
  {
    value: "groupadmin",
    label: getLocaleString("common.super_admin", "Super Admin"),
    id: 1,
    api_role_id: 4,
    isdisabled: false,
  },
  { value: "admin", label: getLocaleString("common.admin", "Admin"), id: 2, api_role_id: 2, isdisabled: false },
  {
    value: "entityadmin",
    label: getLocaleString("common.entity_admin", "Entity Admin"),
    id: 3,
    api_role_id: 5,
    isdisabled: false,
  },
  { value: "user", label: getLocaleString("common.user", "User"), id: 4, api_role_id: 3, isdisabled: false },
  {
    value: "customer",
    label: getLocaleString("common.customer", "Customer"),
    id: 6,
    api_role_id: 6,
    isdisabled: true,
  },
  { value: "arrep", label: getLocaleString("common.ar_rep", "AR Rep"), id: 7, api_role_id: 7, isdisabled: false },
  { value: "aprep", label: getLocaleString("common.ap_rep", "AP Rep"), id: 8, api_role_id: 8, isdisabled: false },
  { value: "supplier", label: getLocaleString("common.vendor", "Vendor"), id: 9, api_role_id: 9, isdisabled: false },
  { value: "driveractivityadmin", label: getLocaleString("common.driver_activity_admin", "Driver Activity Admin"), id: 10, api_role_id: 10, isdisabled: false },
  { value: "readonlyuser", label: getLocaleString("common.read_only_user", "Read Only User"), id: 11, api_role_id: 11, isdisabled: false },
  { value: "readonlyentityuser", label: getLocaleString("common.read_only_entity_user", "Read Only Entity User"), id: 12, api_role_id: 12, isdisabled: false },
  { value: "readonlyaruser", label: getLocaleString("common.read_only_ar_user", "Read Only AR User"), id: 13, api_role_id: 13, isdisabled: false },
  { value: "customer_invoice_restricted", label: getLocaleString("common.customer_restricted", "Customer (Invoice Restricted)"), id: 14, api_role_id: 14, isdisabled: true },
  { value: "supportadmin", label: getLocaleString("page_names.support", "Support"), id: 15, api_role_id: 15, isdisabled: true },
];


const BEworkflowTemplates = [
  // "on_invoice_date",
  // "90_days_after_due_date",
  // "45_days_after_due_date",
  // "7_days_after_due_date",
  // "30_days_after_due_date",
  // "15_days_after_due_date",
  // "on_due_date",
  // "7_days_before_due_date",
];

const disputeReasons = [
  { name: getLocaleString("dispute_code.Unidentified residual", "Unidentified residual"), value: "Unidentified residual" },
  { name: getLocaleString("dispute_code.Quality", "Quality"), value: "Quality" },
  { name: getLocaleString("dispute_code.Quantity", "Quantity"), value: "Quantity" },
  { name: getLocaleString("dispute_code.Price", "Price"), value: "Price" },
  { name: getLocaleString("dispute_code.Cash discount period", "Cash discount period"), value: "Cash discount period" },
  { name: getLocaleString("dispute_code.Cash discount rate", "Cash discount rate"), value: "Cash discount rate" },
  { name: getLocaleString("dispute_code.Cash discount f. net", "Cash discount f. net"), value: "Cash discount f. net" },
  { name: getLocaleString("dispute_code.Cash discount retro", "Cash discount retro."), value: "Cash discount retro." },
  { name: getLocaleString("dispute_code.Disc.period and rate", "Disc.period and rate"), value: "Disc.period and rate" },
  { name: getLocaleString("dispute_code.Pmnt on acct", "Pmnt on acct"), value: "Pmnt on acct" },
  { name: getLocaleString("dispute_code.Pmnt advice error", "Pmnt advice error"), value: "Pmnt advice error" },
  { name: getLocaleString("dispute_code.Calculation error", "Calculation error"), value: "Calculation error" },
  { name: getLocaleString("dispute_code.Debit reduced", "Debit reduced"), value: "Debit reduced" },
  { name: getLocaleString("dispute_code.Debit paid twice", "Debit paid twice"), value: "Debit paid twice" },
  { name: getLocaleString("dispute_code.Credit memo paid", "Credit memo paid"), value: "Credit memo paid" },
  { name: getLocaleString("dispute_code.Credit memo twice", "Credit memo twice"), value: "Credit memo twice" },
  { name: getLocaleString("dispute_code.Shortage&Damage", "Shortage&Damage"), value: "Shortage&Damage" },
];

const CashAppRoute = "/alevate/cashapp";

const Constants = {
  CURRENCY,
  MESSAGES,
  CONFIGS,
  CSVHeader,
  DATE_CONST,
  ROLE_OPTIONS,
  CashAppRoute,
  disputeReasons,
  BEworkflowTemplates
};

export default Constants;
