import { ReducersTypes } from "constants/ReducersTypes";
import { getUXSettingsFromStorage } from "utils";
import moment from "moment";
import { getDateByRangeInterval } from "utils/dates";
import { enableDefaultAllDateFilter } from "handlers/features";
import get from "lodash/get";
import mapValues from "lodash/mapValues";
import { getLocaleString } from "utils/localization/locale";

const dateRange = {
  from_date: enableDefaultAllDateFilter()
    ? moment(0).format("MM/DD/YYYY")
    : moment().subtract(2, "year").format("MM/DD/YYYY"),
  to_date: enableDefaultAllDateFilter()
    ? moment().add(1, "year").format("MM/DD/YYYY")
    : moment().add(365, "days").format("MM/DD/YYYY"),
};

export const defaultColumns = {
  [getLocaleString("common.customer", "Customer")]: true,
  [getLocaleString("dashboard.current", "Current")]: true,
  [getLocaleString("customer.last_month_dso", "Last Month's DSO")]: false,
  [getLocaleString("customer.overdue", "Overdue")]: true,
  [getLocaleString("common.amountPaid", "Amount Paid")]: true,
  [getLocaleString("customer.show_aging_buckets", "Show aging buckets")]: false,
  [getLocaleString("dashboard.total_balance", "Total Balance")]: true,
  [getLocaleString("reconcile.credit", "Credit")]: false,
  [getLocaleString("common.unapplied_payments", "Unapplied Payments")]: false,
  [getLocaleString("common.customer_category", "Customer Category")]: false,
  [getLocaleString(
    "customer.payment_standard_deviation",
    "Payment Standard Deviation"
  )]: true,
  [getLocaleString("dashboard.avg_days_late", "Avg Days Late")]: false,
  [getLocaleString(
    "dashboard.wt_avg_days_late",
    "Weighted(avg) Days Late"
  )]: false,
  [getLocaleString("dashboard.payment_terms", "Payment Terms")]: true,
  [getLocaleString("customer.workflow_used", "Workflow Used")]: true,
  [getLocaleString("customer.workflow_status", "Workflow Status")]: true,
  [getLocaleString("customer.last_activity_date", "Last Activity Date")]: false,
  [getLocaleString("common.internal_id", "Internal ID")]: false,
  [getLocaleString("common.notes", "Notes")]: true,
};

export const CUSTOMER_LIST_COLUMNS = {
  totalAR: {
    [getLocaleString("common.customer", "Customer")]: true,
    [getLocaleString("common.customer_category", "Customer Category")]: false,
    [getLocaleString("common.amountPaid", "Amount Paid")]: true,
    [getLocaleString(
      "customer.payment_standard_deviation",
      "Payment Standard Deviation"
    )]: true,
    [getLocaleString("dashboard.avg_days_late", "Avg Days Late")]: false,
    [getLocaleString(
      "dashboard.wt_avg_days_late",
      "Weighted(avg) Days Late"
    )]: false,
    [getLocaleString("dashboard.payment_terms", "Payment Terms")]: true,
    [getLocaleString("customer.workflow_used", "Workflow Used")]: true,
    [getLocaleString("customer.workflow_status", "Workflow Status")]: true,
    [getLocaleString(
      "customer.last_activity_date",
      "Last Activity Date"
    )]: false,
    [getLocaleString("common.internal_id", "Internal ID")]: false,
    [getLocaleString("common.notes", "Notes")]: true,
  },
  default: defaultColumns,
  araging: defaultColumns,
  araginginsight: defaultColumns,
  riskprofile: defaultColumns,
  spendingCategory: defaultColumns,
  projected_cash: defaultColumns,
};

let i = 1;
export const CUSTOMER_LIST_COLUMNS_ORDER = mapValues(defaultColumns, () => i++);
i = 1;

export const CUSTOMER_INVOICE_DETAILS_COLUMNS = {
  Invoice: true,
  "PayIQ Date": true,
  "Invoice Date": false,
  "Due Date": true,
  "Days Past Due": true,
  "PO Number": true,
  Memo: true,
  "Project ID": false,
  "Closed Date": false,
  "Invoice Amount": true,
  "Amount Due": true,
  "Amount Paid": true,
  "Payment Method": true,
  Entity: false,
  Status: false,
  "Pay Now": true,
};

export const UNAPPLIED_PAYMENTS_CREDIT_FLAGS = {
  showUnappliedAmount: false,
  showUnappliedMemo: false,
};

export const columnWidthCustomer = {
  name: 200,
  outstanding_balance: 100,
  dso: 150,
  overdue_balance: 100,
  total_balance: 120,
  unapplied_payment: 200,
  aggregate_credit: 100,
  total_amount_paid: 100,
  average_days_late: 120,
  weighted_avg_days_late: 120,
  payment_terms: 125,
  workflow_name: 150,
  workflow_status: 150,
  customer_notes: 40,
  payment_standard_deviation: 200,
};

export const initialCustomerListFilters = {
  page: 1,
  sort_by: "total_balance",
  tag_ids: [],
  interval: "",
  activeKey: localStorage.getItem("customer_activeTab"),
  sort_order: "desc",
  risk_score: "",
  with_notes: 1,
  ...dateRange,
  isProcessing: false,
  spendCategory: "",
  selected_arrep: "",
  startDate: dateRange.from_date,
  endDate: dateRange.to_date,
  originalendDate: dateRange.to_date,
  activeGraphFilter: null,
  originalstartDate: dateRange.from_date,
  send_custom_fields: 0,
  appliedCustomFilters: {},
  include_all_customers: 1,
  customer_category_id: "",
  include_custom_field_ids: [],
};

let savedFilters = getUXSettingsFromStorage("CUSTOMER_LISTING_FILTERS");
if (savedFilters && savedFilters.interval) {
  const { interval, from_date, to_date } = savedFilters;
  const [sd, ed] = getDateByRangeInterval(
    interval === "All" ? "AllToDate" : interval,
    from_date,
    to_date
  );
  savedFilters = {
    ...savedFilters,
    originalstartDate: sd,
    originalendDate: ed,
    from_date: sd,
    to_date: ed,
  };
}
const updatedColumns = JSON.stringify(
  getUXSettingsFromStorage("CUSTOMER_LIST_COLUMNS")
).includes("araginginsight")
  ? getUXSettingsFromStorage("CUSTOMER_LIST_COLUMNS")
  : { default: getUXSettingsFromStorage("CUSTOMER_LIST_COLUMNS") };

const finalTabSpecificColumns = Object.keys(CUSTOMER_LIST_COLUMNS).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: {
      ...get(CUSTOMER_LIST_COLUMNS, `${cur}`, {}),
      ...get(updatedColumns, `${cur}`, {}),
    },
  }),
  {}
);

export const initCustomerListData = {
  listData: [],
  child_rows: [],
  selectedRows: [],
  accounts: [],
  showPaymentDialog: false,
  isFetching: false,
  exportedData: [],
  isExporting: false,
  showgraphs: false,
  selectedSectionArAging: null,
  selectedSectionRiskProfile: null,
  workflowSteps: [],
  showWorkflow: false,
  selectedWorkflow: "",
  selectedCustomerForWF: "",
  tabSpecificColumns: finalTabSpecificColumns,
  visibleColumns: defaultColumns,
  showEmailPopup: false,
  emailCustomerId: "",
  callBackWorkflowData: null,
  visibleColumnsShow: false,
  emailForWorkflow: "",
  customFields: [],
  filters: {
    ...initialCustomerListFilters,
    ...savedFilters,
  },
};

const customerList = (state = initCustomerListData, action) => {
  switch (action.type) {
    case ReducersTypes.CUSTOMER_LIST: {
      return { ...state, ...action.payload };
    }
    case ReducersTypes.CUSTOMER_LIST_UPDATE: {
      return { ...state, ...action.payload };
    }
    case ReducersTypes.RESET_CUSTOMER_LIST:
      return { ...initCustomerListData };
    case ReducersTypes.SET_NEW_CUSTOMER_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case ReducersTypes.RESET_NEW_CUSTOMER_FILTER:
      return {
        ...state,
        filters: {
          ...initialCustomerListFilters,
          send_custom_fields: get(state, "filters.send_custom_fields", ""),
          activeKey: get(state, "filters.activeKey", ""),
          ...action.payload,
        },
      };
    case ReducersTypes.HEADER_REFRESH_TOGGLE:
      return {
        ...state,
        listData: [],
        child_rows: [],
        filters: {
          ...initialCustomerListFilters,
          send_custom_fields: get(state, "filters.send_custom_fields", ""),
          activeKey: get(state, "filters.activeKey", ""),
        },
      };
    case ReducersTypes.SET_CURRENT_CUSTOMER_WF: {
      return {
        ...state,
        selectedWorkflow: action?.payload?.workflow_sequence_id,
        selectedCustomerForWF: action.payload,
      };
    }
    default:
      return state;
  }
};

export default customerList;
