import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import { enableDefaultAllDateFilter } from "handlers/features";
import { getUXSettingsFromStorage } from "utils";
import { getDateByRangeInterval } from "utils/dates";
import get from "lodash/get";
import omit from "lodash/omit";
import mapValues from "lodash/mapValues";
import { getLocaleString } from "utils/localization/locale";

export const RECEIVABLE_LIST_COLUMNS = {
  [getLocaleString("common.invoice", "Invoice")]: true,
  [getLocaleString("common.customer", "Customer")]: true,
  [getLocaleString("common.customer_category", "Customer Category")]: true,
  [getLocaleString("common.lineCategory", "Line Category")]: false,
  [getLocaleString("common.project_id", "Project ID")]: true,
  [getLocaleString("common.payIQDate", "PayIQ Date")]: true,
  [getLocaleString("common.dueDate", "Due Date")]: true,
  [getLocaleString("common.invoiceDate", "Invoice Date")]: true,
  [getLocaleString("common.closedDate", "Closed Date")]: true,
  [getLocaleString("common.amountDue", "Amount Due")]: true,
  [getLocaleString("common.amountPaid", "Amount Paid")]: true,
  [getLocaleString("common.assinged_to", "Assigned To")]: false,
  [getLocaleString("common.entity", "Entity")]: false,
  [getLocaleString("common.status", "Status")]: true,
  [getLocaleString("payables.payablesList.sentiment", "Sentiment")]: true,
  [getLocaleString("common.tags", "Tags")]: false,
  [getLocaleString("common.notes", "Notes")]: false,
  [getLocaleString("common.memo", "Memo")]: false,
  [`${getLocaleString("common.Dispute", "Dispute")} ${getLocaleString("receivables.reason_codes", "Reason codes")}`]: false
};

let i = 1;
export const RECEIVABLE_LIST_COLUMNS_ORDER = mapValues(RECEIVABLE_LIST_COLUMNS, () => i++);
i = 1;

export const boxes = [
  { id: 0, type: getLocaleString("common.totalAmount", "Total Amount"), amount: 0, isFetching: false },
  { id: 1, type: getLocaleString("customer.overdue", "Overdue"), amount: 0, isFetching: false },
  { id: 3, type: getLocaleString("dashboard.current", "Current"), amount: 0, isFetching: false },
  { id: 4, type: getLocaleString("receivables.paid_late", "Paid late "), amount: 0, isFetching: false },
  { id: 2, type: getLocaleString("receivables.paid_on_time", "Paid on time"), amount: 0, isFetching: false },
];

const dateRange = {
  from_date: enableDefaultAllDateFilter()
    ? moment(0).format("MM/DD/YYYY")
    : moment().format("MM/DD/YYYY"),
  to_date: enableDefaultAllDateFilter()
    ? moment().add(1, "year").format("MM/DD/YYYY")
    : moment().add(30, "days").format("MM/DD/YYYY"),
  interval: enableDefaultAllDateFilter() ? "All" : "Next 30 Days",
};

let savedFilters = getUXSettingsFromStorage("RECEIVABLE_FILTERS");
if (savedFilters && savedFilters.interval) {
  const { interval, from_date, to_date } = savedFilters;
  const updatedKeys = {};
  const [sd, ed] = getDateByRangeInterval(
    interval === "All" ? "AllToDate" : interval,
    from_date,
    to_date
  );
  if (
    [
      "1-30 Days Overdue",
      "31-60 Days Overdue",
      "61-90 Days Overdue",
      "> 90 Days Overdue",
    ].includes(interval)
  ) {
    updatedKeys.date_type = "due";
    updatedKeys.status = "open";
  }
  savedFilters = {
    ...savedFilters,
    ...updatedKeys,
    bandFilteredStatus: savedFilters.status,
    from_date: sd,
    to_date: ed,
  };
}

export const initialReceivablesFilters = {
  ...dateRange,
  page: 1,
  status: "open",
  tag_ids: [],
  sort_by: "amount_due",
  category: "",
  date_type: "expected",
  sentiment: "",
  sort_order: "desc",
  assignedTo: null,
  not_tag_ids: [],
  invoice_type: "INVOICE",
  invoiceSelect: null,
  corp_entity_id: "",
  customerSelect: null,
  amount_less_than: "",
  amount_greater_than: "",
  appliedCustomFilters: {},
  show_payiq_overriden: true,
  is_reminders_not_sent: false,
  line_customer_category_id: "",
  header_customer_category_id: "",
};

export const handleSavedFilters = (filters) => {
  const updatedFilters = {};
  let omitFilters = [];
  if (get(filters, "custom_field_master_id", "")) {
    omitFilters.push("custom_field_master_id");
    const customField = get(filters, "custom_field_master_id")
      .split(",")
      .reduce((acc, cur) => {
        const csFilter = Object.keys(filters).reduce((a, c) => {
          if (c.includes(cur)) {
            omitFilters.push(c);
            a = {
              ...a,
              [c]: filters[c] ? decodeURIComponent(filters[c]) : "",
            };
          }
          return a;
        }, {});
        return (acc = {
          ...acc,
          [Number(cur)]: {
            custom_field_master_id: Number(cur),
            ...csFilter,
          },
        });
      }, {});
    updatedFilters.appliedCustomFilters = customField;
  }
  if (get(filters, "invoice_id", "")) {
    omitFilters.push("invoice_id");
    updatedFilters.invoiceSelect = {
      name: get(filters, "invoice_id", ""),
      id: get(filters, "invoice_id", ""),
    };
  }
  if (get(filters, "customer_id", "")) {
    omitFilters.push("customer_id");
    omitFilters.push("customer_name");
    updatedFilters.customerSelect = {
      name: get(filters, "customer_name", ""),
      id: get(filters, "customer_id", ""),
    };
  }
  if (get(filters, "user_role_ids", "")) {
    omitFilters.push("user_role_ids");
    updatedFilters.assignedTo = get(filters, "user_role_ids", "");
  }
  return omit({ ...filters, ...updatedFilters }, omitFilters);
};

const initialReceivablesData = {
  invoiceList: [],
  exportedData: [],
  isFetching: false,
  isExporting: false,
  getCustomFieldsData: true,
  filters: {
    ...initialReceivablesFilters,
    ...handleSavedFilters(savedFilters),
  },
  visibleColumns: {
    ...RECEIVABLE_LIST_COLUMNS,
    ...getUXSettingsFromStorage("RECEIVABLE_LIST_COLUMNS"),
  },
};

const receivables = (state = initialReceivablesData, action) => {
  switch (action.type) {
    case ReducersTypes.SET_RECEIVABLE_LIST_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case ReducersTypes.SET_RECEIVABLE_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case ReducersTypes.RESET_RECEIVABLE_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...initialReceivablesFilters,
          ...action.payload,
        },
      };

    case ReducersTypes.UPDATE_RECEIVABLES_STATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default receivables;
